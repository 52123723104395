import React from 'react';
import classNames from 'classnames';
import { Flex } from 'bb/ui/Flex';
import { Box, type BoxProps } from '../../Box';
import { Gap } from '../../Gap';
import { ImageIcon, type SvgName } from '../../Icons';
import css from './badgeV2.module.scss';

const CONTAINER_BG_COLOR = 'tertiary-purple';

type BadgeBaseProps = {
    icon?: SvgName;
    iconBgColor?: BoxProps['bgColor'];
};

const getPadding = ({ icon, iconBgColor }: BadgeBaseProps) => {
    let padding = [0, 2];
    if (icon) {
        padding = [0, 2, 0, 0];
    }
    if (icon && iconBgColor === CONTAINER_BG_COLOR) {
        padding = [2, 3, 2, 2];
    }
    return padding;
};

export type BadgeProps = BadgeBaseProps & Omit<BoxProps, 'padding'>;

export const BadgeV2 = (props: BadgeProps) => {
    const {
        icon,
        iconBgColor = 'primary-purple',
        className,
        children,
        ...rest
    } = props;

    const padding = getPadding({ icon, iconBgColor });

    return (
        <Box
            bgColor={CONTAINER_BG_COLOR}
            padding={padding as BoxProps['padding']}
            className={classNames(css.base, className)}
            {...rest}
        >
            <Gap
                spacing={2}
                alignItems="center"
                justifyContent="center"
                direction="row"
                className={css.minHeight}
            >
                {icon && (
                    <Box bgColor={iconBgColor} className={css.icon}>
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            className={css.minHeight}
                        >
                            <ImageIcon svg={icon} size="small" />
                        </Flex>
                    </Box>
                )}
                {children}
            </Gap>
        </Box>
    );
};
